$(document).on("turbolinks:load", () => {
  var url = '';
  var $input_group = $('.input__cont');
  moment = require('moment');
  if ($('#message-form').length) {
    getChatsList(chatsListAll);
    const { Dropzone } = require("dropzone");
    Dropzone.autoDiscover = false;

    var messageDropzone = new Dropzone('#message-form', {
      clickable: '.input__attachment-label',
      acceptedFiles: '.jpg,.jpeg,.png,application/pdf',
      autoProcessQueue: false,
      uploadMultiple: true,
      parallelUploads: 100,
      maxFiles: 100,
      maxFilesize: 16,
      createImageThumbnails: false,

      init: function() {
        var myDropzone = this;
        var has_image = false;
        this.element.querySelector(".input__send").addEventListener("click", function(e) {
          e.preventDefault();
          e.stopPropagation();
          if (has_image)
            myDropzone.processQueue();
          else
            $('#message-form').trigger('submit');
        });
        this.element.querySelector(".input__attachment-label").addEventListener("click", function(e) {
          myDropzone.removeAllFiles();
        });
        this.on('addedfile', function(){
          has_image = true;
          $('.input__attachment-label').addClass("has-background-success has-text-white");
          $('.input__send').removeAttr('disabled');
        });
        this.on('removedfile', function(){
          has_image = false;
          $('.input__attachment-label').removeClass("has-background-success has-text-white");
          $('.input__send').attr('disabled','disabled');
        });
        this.on("successmultiple", function(files, response) {
          setTemporalMessages();
          resetInput();
          myDropzone.removeAllFiles();
        });
        this.on("errormultiple", function(file, errorMessage, xhr) {
          if (errorMessage.includes('File is too big'))
            showMaxSizeError();
          else
            showServerError();
          myDropzone.removeAllFiles();
        });
      }
    });
  };

  $(document).on('click', '.ft_transactions_chat .ft_chat', function(ev){
    ev.preventDefault();
    $('.ft_transactions_chat').removeClass('ft-selected');
    $(this).parent().addClass('ft-selected');
    if ($(this).hasClass('new')){
      $(this).removeClass('new');
      sendReadChat($(this).parent().attr('data-t-id'));
    }
    showChatInfo();
    $('.ft_panel_list_chat_visible').removeClass('visible').addClass("is-hidden-mobile");
    $('.ft_panel_chat_visible').removeClass('is-hidden-mobile').addClass("visible");
  });

  $('.ft_panel_chat_button_back').click(function() {
    $('.ft_panel_list_chat_visible').removeClass('is-hidden-mobile').addClass("visible");
    $('.ft_panel_chat_visible').removeClass('visible').addClass("is-hidden-mobile");
  });

  $('.active_open_tabs_friendly_transaction').click(function() {
    if($('#open_friendly_transaction').length != 0) {
      $('.ft_transactions_chats_open').removeClass('is-hidden').addClass("visible");
      $('.ft_transactions_chats_finished').addClass('is-hidden').removeClass("visible");
      $('.active_open_tabs_friendly_transaction').addClass('is-active');
      $('.active_finished_tabs_friendly_transaction').removeClass('is-active');
    };
  });

  $('.active_finished_tabs_friendly_transaction').click(function() {
    if($('#finished_friendly_transaction').length != 0) {
      $('.ft_transactions_chats_finished').removeClass('is-hidden').addClass("visible");
      $('.ft_transactions_chats_open').addClass('is-hidden').removeClass("visible");
      $('.active_open_tabs_friendly_transaction').removeClass('is-active');
      $('.active_finished_tabs_friendly_transaction').addClass('is-active');
    };
  });

  $('.transactions__search-bar').keyup(search);

  if ($('.ft_scroll_view').length != 0) {
    $('.ft_scroll_view').animate({ scrollTop: $(document).height() }, 1000);
  };

  $('.button_modal_pay_transferred').click(function() {
    $('.modal_pay_transferred').addClass('is-active');
    $('.button_close_pay').click(function() {
      $('.modal_pay_transferred').removeClass('is-active');
    });
  });

  $('.button_modal_confirmed_transferred').click(function() {
    $('.modal_confirmed_transferred').addClass('is-active');
    $('.button_close_confirm').click(function() {
      $('.modal_confirmed_transferred').removeClass('is-active');
    });
  });

  $('.button_modal_video_help_me, .landing_button_modal_video_help_me').click(function() {
    $('.modal_video_help_me').addClass('is-active');
    $('.button_close_video_help_me').click(function() {
      $('.modal_video_help_me').removeClass('is-active');
    });
  });

  $('.all-digital-modal').click(function() {
    $('#modal-all-digital').addClass('is-active');
    $('.modal-close-all-digital').click(function() {
      $('#modal-all-digital').removeClass('is-active');
    });
  });

  $('.ft_btn_close_on_press').click(function () {
    $('.ft_info_bar_btn').toggleClass('is-hidden')
  });

  $('#message-form').submit(function(event) {
    var values = {};
    event.preventDefault();
    $('#message-form :input').each(function() {
      values[this.name] = $(this).val();
    });
    addTemporalMessage(values["message[content]"]);
    resetInput();
    $.ajax({
      url: $('#message-form').attr('action'),
      method: 'post',
      dataType: 'json',
      data: values,
    }).done(
      function(response){
        if (response['status'] == 'OK') {
          setTemporalMessages();
        } else {
          showServerError();
        }
      }
    ).fail(showServerError); 
  });

  $('.input__area').on('keyup', function() {
    if($('.ft_transactions_chat.selected').hasClass('new')){
      sendReadChat($('.ft_transactions_chat.selected').attr('data-t-id'));
    }
    if ($(this).val()) {
      $('.input__send').removeAttr('disabled');
    } else {
      $('.input__send').attr('disabled', 'disabled');
    }
  });

  $('.input__area').click('keyup click', function() {
    if ($input_group.hasClass('is-danger')) {
      $input_group.removeClass('is-danger');
      $(this).val('');
      $('.input__send').attr('disabled', 'disabled');
    }
  });

  function chatsListAll(){
    var url = window.location.href.split('/');
    var selected_id = url.pop();
    $('.ft_transactions_chat[data-t-id="'+selected_id+'"]').addClass('ft-selected');
    if ($('.ft_transactions_chat.ft-selected').length){
      if ($('.ft_transactions_chat.ft-selected').parents('#finished_friendly_transaction').length > 0){
        showClosedTransactions();
        scrollToElement('#finished_friendly_transaction', '.ft_transactions_chat.ft-selected');
      } else {
        scrollToElement('#open_friendly_transaction', '.ft_transactions_chat.ft-selected');
      };
      showChatInfo();
    };
  };

  function showClosedTransactions(){
    $('#finished_friendly_transaction').removeClass('is-hidden').addClass('visible');
    $('#open_friendly_transaction').addClass('is-hidden').removeClass('visible');
  };

  function scrollToElement(parent, element){
    if ($(element).offset()){
      offset = $(element).offset().top - $(element).parent().offset().top;
      setTimeout(function(){   
        $(parent).scrollTop(offset);
      },100);
    };
  };

  function showChatInfo() {
    $this = $('.ft_transactions_chat.ft-selected');
    $('.msg:not(.msg--model)').remove();
    url = $this.children('.ft_chat').attr('href');
    getMessages();
    $(".ft_chat_transaction_id").val($this.attr('data-t-id'));
    $(".ft_chat_bar_img").attr('style', $this.find('.ft_chat_img').attr('style'));
    $(".ft_chat_bar_img").addClass('ft_chat_img_profile_info');
    $(".ft_chat_name_info").text($this.find('.ft_chat_name').text());
    $(".ft_chat_name_info").attr('href', $this.find('.ft_chat_profile_link').text());
    $(".ft_chat_amount_info").html($this.find('.ft_chat_amount').html());
    $(".ft_chat_country_need_info").html($this.find('.ft_chat_country_need').html());
    $(".ft_chat_country_offer_info").html($this.find('.ft_chat_country_offer').html());
    $(".ft_steps_progress_0").addClass($this.find('.ft_steps_0').attr('class'));
    $(".ft_steps_progress_1").addClass($this.find('.ft_steps_1').attr('class'));
    $(".ft_steps_progress_2").addClass($this.find('.ft_steps_2').attr('class'));
    $(".ft_steps_progress_3").addClass($this.find('.ft_steps_3').attr('class'));
    ft_btn_close = $this.find('.ft_transaction_status').attr('status') === "Active" || $this.find('.ft_transaction_steps').attr('steps') === "Initialized";
    ft_btn_progress = $this.find('.ft_transaction_steps').attr('steps') === "Pay" || $this.find('.ft_transaction_steps').attr('steps') === "Confirming";
    ft_btn_completed = $this.find('.ft_transaction_steps').attr('steps') === "Completed" || $this.find('.ft_transaction_status').attr('status') === "Finished";
    if ($this.find('.ft_transaction_is_express').attr('is_express') === "true") {
      $(".ft_chat_country_need_info").addClass('has-text-danger');
      $(".ft_chat_country_offer_info").addClass('has-text-danger');
    } else {
      $(".ft_chat_country_need_info").removeClass('has-text-danger');
      $(".ft_chat_country_offer_info").removeClass('has-text-danger');
    };
    ft_value_reviews_pos = $this.find('.ft_reviews_user_pos').attr('reviews_user_pos') === $this.find('.ft_reviews_pos').attr('reviews_pos') || $this.find('.ft_reviews_user_pos').attr('reviews_user_pos') > $this.find('.ft_reviews_pos').attr('reviews_pos');
    if (ft_value_reviews_pos) {
      $(".ft_chat_btn_pay_progress").hide();
      $(".ft_chat_btn_confirmed_progress").show();
    } else {
      $(".ft_chat_btn_pay_progress").show();
      $(".ft_chat_btn_confirmed_progress").hide();
    };
    if (ft_btn_close) {
      $(".ft_btn_close_transaction").show();
    } else {
      $(".ft_btn_close_transaction").hide();
    };
    if (ft_btn_progress) {
      $(".ft_btn_progress_transaction").show();
      $(".ft_btn_close_transaction").hide();
    } else {
      $(".ft_btn_progress_transaction").hide();
    };
    if (ft_btn_completed) {
      $(".ft_btn_completed_transaction").show();
      $(".ft_btn_close_transaction").hide();
      $(".ft_btn_progress_transaction").hide();
      $(".ft_transactions_chat_footer").hide();
    } else {
      $(".ft_btn_completed_transaction").hide();
      $(".ft_transactions_chat_footer").show();
    };
    $(".button_modal_pay_transferred").hide();
    $(".button_modal_confirmed_transferred").hide();
    if ($this.find('.ft_transaction_steps').attr('steps') == "Initialized") {
      $(".button_modal_pay_transferred").show();
    };
    if ($this.find('.ft_transaction_steps').attr('steps') == "Initialized" || $this.find('.ft_transaction_steps').attr('steps') == "Pay") {
      $(".button_modal_confirmed_transferred").show();
    };
  };

  function getMessages() {
    $.ajax({
      url, method: 'get', dataType: 'json'
    }).done(
      setChatMessages
    ).fail(
      function(response){
        console.log("error", response);
      }
    );
  };

  function setChatMessages(response){
    $('.ft_chat_message_progress_bars').show();
    $('.ft_chat_message_progress_bars').removeClass('is-hidden');
    $('.ft_chat_message_progress_bars').css('display', 'flex');
    $('.ft_info_bar').show();
    $('.ft_info_bar').removeClass('is-hidden');
    $('.ft_info_bar').css('display', 'flex');
    $('.ft-wrapper').show();
    $('.ft-wrapper').removeClass('is-hidden');
    $('.ft_chat_message_footer').show();
    $('.ft_chat_message_footer').removeClass('is-hidden');
    transaction_id = response['ft_transaction_res'][0];
    $('.ft_chat_transaction_id').val(transaction_id.toString());
    messages = response['messages'];
    $('.transactions__msgs-cont').children('.msg:not(.msg--model)').remove();
    for(i in messages){
      message_txt = messages[i][1];
      if (i != 0 || message_txt != 'None'){
        var $msg = null
        if (messages[i][0] === "other") {
          $msg = $('<article></article>').addClass('is-flex mb-2 is-justify-content-start msg msg--'+messages[i][0]);
          $msg.html($('.msg.msg--model').html());
          $msg.find('.msg__body').addClass('is-info ft-style-msg-body-other is-justify-content-start');
        } else if (messages[i][0] === "own") {
          $msg = $('<article></article>').addClass('is-flex mb-2 is-justify-content-end msg msg--'+messages[i][0]);
          $msg.html($('.msg.msg--model').html());
          $msg.find('.msg__body').addClass('is-success has-text-right mr-3 ft-style-msg-body-own is-justify-content-end');
        } else if (messages[0][0] === "system"){
          $msg = $('<article></article>').addClass('is-flex mb-2 is-justify-content-center msg msg--'+messages[i][0]);
          $msg.html($('.msg.msg--model').html());
          $msg.find('.msg__body').addClass('is-info is-justify-content-center ft-style-msg-body-system').removeClass('is-light');
        };
        $msg.find('.msg__text').text(message_txt);
        $msg.find('.msg__date').text(moment(messages[i][2]).format('MMM D, YYYY h:mma'));
        if (messages[i][3]){
          $msg.find('.msg__img').attr('src', messages[i][3]);
          $msg.find('.msg__img').attr('width', 200);
          $msg.find('.msg__img').attr('height', 200);
          $msg.find('.msg__img-link').css('display', 'block').attr('href', messages[i][3]);
        } else {
          $msg.find('.msg__img-link').css('display', 'none');
        };
        $msg.appendTo('.transactions__msgs-cont');
        if (i == messages.length -1){
          scrollToBottom('.transactions__msgs-cont');
        };
      };
    };
  };

  function setTemporalMessages(){
    $this = $('.msg.msg--own.temporal').first();
    $this.removeClass('temporal');
    if($('.msg.msg--own.temporal').length < 1){
      getMessages();
    };
  };

  function resetInput(){
    $(".input__area").val("");
    $('.input__send').attr('disabled', 'disabled');
  };

  function showMaxSizeError(){
    showErrorMessage('Maximum image size is 2MB');
  };

  function showErrorMessage(errorMessage){
    $input_group.addClass('is-danger');
    $('.input__send').attr('disabled', 'disabled');
    $(".input__area").val(errorMessage);
    removeTemporalMessage();
  };

  function removeTemporalMessage(){
    $('.msg.msg--own.temporal').remove();
  };

  function showServerError(){
    window.location="/membership";
  };

  function search() {
    var text = $('.transactions__search-bar').val().toLowerCase();
    $('.ft_transactions_chat').each(function() {
      var html = $(this).find('.ft_chat').html().toLowerCase();
      if (html.includes(text)) {
        $(this).css('display', 'grid');
      } else {
        $(this).hide();
      };
    });
  };

  function addTemporalMessage(message){
    $temporalMessage = $('<article></article>').addClass('is-flex mb-2 is-justify-content-end msg msg--own temporal');
    $temporalMessage.html($('.msg.msg--model').html());
    $temporalMessage.find('.msg__body').addClass('is-success ft-style-msg-body-own');
    $temporalMessage.find('.msg__text').text(message);
    $temporalMessage.appendTo('.transactions__msgs-cont');
    scrollToBottom('.transactions__msgs-cont');
  };

  function scrollToBottom(element) {
    $d = $(element);
    setTimeout(function(){
      $d.scrollTop($d.prop("scrollHeight"));
    },100);
  };

});

function getChatsList(callback) {
  $.ajax({
    url: '/chats',
    method: 'get',
    dataType: 'json'
  }).done(
    showChatListData
  ).fail(
    function(response){
      console.log("error", response);
    }
  );

  function showChatListData(response){
    $('.ft_yield .spinner').show();
    $('#no-open-chats, #no-closed-chats').hide();
    $('.ft_chat_message_progress_bars').hide();
    $('.ft_chat_message_progress_bars').addClass('is-hidden');
    $('.ft_info_bar').hide();
    $('.ft_info_bar').addClass('is-hidden');
    $('.ft-wrapper').hide();
    $('.ft-wrapper').addClass('is-hidden');
    $('.ft_chat_message_footer').hide();
    $('.ft_chat_message_footer').addClass('is-hidden');
    var openChats = response.open;
    var finishedChats = response.finished;
    var openChatData, closedChatData, $chat;
    var selected_id = $('.ft_transactions_chat.ft-selected').attr('data-t-id');
    $('.ft_transactions_chat:not(.is-hidden)').remove();
    if(openChats.length > 0) {
      for(var i in openChats){
        openChatData = openChats[i];
        $chat = fillChatData(openChatData, selected_id);
        $chat.appendTo('#open_friendly_transaction');
      };
    } else {
      $('#no-open-chats').show();
    };
    if(finishedChats.length > 0){
      for(var i in finishedChats){
        closedChatData = finishedChats[i];
        $chat = fillChatData(closedChatData, selected_id);
        $chat.appendTo('#finished_friendly_transaction');
      };
    } else {
      $('#no-closed-chats').show();
    };
    // $('[data-toggle="tooltip"]').tooltip();
    $('.ft_yield .spinner').hide();
    if(typeof(callback) == 'function')
      callback();
  };

  function copyChatModel(){
    $chat = $('.ft_transactions_chat.is-hidden')
      .clone()
      .removeClass('is-hidden');
    return $chat;
  };

  function fillChatData(chatData, selected_id){
    $chat = copyChatModel();
    $chat.attr('data-t-id', chatData.id);
    if (chatData.id == selected_id)
      $chat.addClass('ft-selected');
      $chat.find('.ft_chat').attr('href', '/friendly_transactions/'+chatData.id+'/show');
      $chat.find('.ft_chat').attr('style', 'color: #4a4a4a;');
      $chat.find('.ft_chat_img').css('background-image', 'url('+chatData.avatar+')');
      $chat.find('.ft_chat_name').text(chatData.name);
      $chat.find('.ft_chat_profile_link').text('/users/' + chatData.user_id);
      $chat.find('.ft_chat_date_create_at').text(moment(chatData.date).format('MMM D, YYYY'));
      $chat.find('.ft_chat_amount').text("$" + chatData.amount);
      $chat.find('.ft_steps_0').addClass(chatData.steps_progress.steps_0.show);
      $chat.find('.ft_steps_1').addClass(chatData.steps_progress.steps_1.show);
      $chat.find('.ft_steps_2').addClass(chatData.steps_progress.steps_2.show);
      $chat.find('.ft_steps_3').addClass(chatData.steps_progress.steps_3.show);
      $chat.find('.ft_transaction_status').attr('status', chatData.status);
      $chat.find('.ft_transaction_steps').attr('steps', chatData.steps);
      $chat.find('.ft_transaction_is_express').attr('is_express', chatData.is_express);
      $chat.find('.ft_reviews_pos').text(chatData.reviews_pos);
      $chat.find('.ft_reviews_pos').attr('reviews_pos', chatData.reviews_pos);
      $chat.find('.ft_reviews_neg').text(chatData.reviews_neg);
      $chat.find('.ft_reviews_user_pos').attr('reviews_user_pos', chatData.reviews_user_pos)
    if(chatData.is_express) {
      $chat.find('.ft_chat_certified_img').show();
      $chat.find('.ft_chat_country_need').text(chatData.country_offer);
      $chat.find('.ft_chat_country_offer').text(chatData.country_need);
      $chat.find('.ft_chat_name').addClass('has-text-danger');
      $chat.find('.ft_chat_amount').addClass('has-text-danger');
      $chat.find('.ft_chat_country_need').addClass('has-text-danger');
      $chat.find('.ft_chat_country_offer').addClass('has-text-danger');
    }else{
      $chat.find('.ft_chat_certified_img').hide();
      $chat.find('.ft_chat_country_need').text(chatData.country_need);
      $chat.find('.ft_chat_country_offer').text(chatData.country_offer);
    }
    if(chatData.has_subscription)
      $chat.find('.ft_chat_check_img').show();
    else
      $chat.find('.ft_chat_check_img').hide();
    return $chat;
  };
}
