import consumer from "./consumer"

function getMessages(data) {
  var url = '/friendly_transactions/'+ data.resource_id + '/show';
  if ($('.ft_transactions_chat').length && data.resource_id == $('.ft_chat_transaction_id').val()) {
    $.ajax({
      url, method: 'get', dataType: 'json'
    }).done(
      setChatMessages
    ).fail(
      function(response){
        console.log("error", response);
      }
    );
  }
};

function setChatMessages(response){
  $('.ft_chat_message_progress_bars').show();
  $('.ft_chat_message_progress_bars').removeClass('is-hidden');
  $('.ft_chat_message_progress_bars').css('display', 'flex');
  $('.ft_info_bar').show();
  $('.ft_info_bar').removeClass('is-hidden');
  $('.ft_info_bar').css('display', 'flex');
  $('.ft-wrapper').show();
  $('.ft-wrapper').removeClass('is-hidden');
  $('.ft_chat_message_footer').show();
  $('.ft_chat_message_footer').removeClass('is-hidden');
  transaction_id = response['ft_transaction_res'][0];
  $('.ft_chat_transaction_id').val(transaction_id.toString());
  messages = response['messages'];
  $('.transactions__msgs-cont').children('.msg:not(.msg--model)').remove();
  for(i in messages){
    message_txt = messages[i][1];
    if (i != 0 || message_txt != 'None'){
      var $msg = null
      if (messages[i][0] === "other") {
        $msg = $('<article></article>').addClass('is-flex mb-2 is-justify-content-start msg msg--'+messages[i][0]);
        $msg.html($('.msg.msg--model').html());
        $msg.find('.msg__body').addClass('is-info ft-style-msg-body-other is-justify-content-start');
      } else if (messages[i][0] === "own") {
        $msg = $('<article></article>').addClass('is-flex mb-2 is-justify-content-end msg msg--'+messages[i][0]);
        $msg.html($('.msg.msg--model').html());
        $msg.find('.msg__body').addClass('is-success has-text-right mr-3 ft-style-msg-body-own is-justify-content-end');
      } else if (messages[0][0] === "system"){
        $msg = $('<article></article>').addClass('is-flex mb-2 is-justify-content-center msg msg--'+messages[i][0]);
        $msg.html($('.msg.msg--model').html());
        $msg.find('.msg__body').addClass('is-info is-justify-content-center ft-style-msg-body-system').removeClass('is-light');
      };
      $msg.find('.msg__text').text(message_txt);
      $msg.find('.msg__date').text(moment(messages[i][2]).format('MMM D, YYYY h:mma'));
      if (messages[i][3]){
        $msg.find('.msg__img').attr('src', messages[i][3]);
        $msg.find('.msg__img').attr('width', 200);
        $msg.find('.msg__img').attr('height', 200);
        $msg.find('.msg__img-link').css('display', 'block').attr('href', messages[i][3]);
      } else {
        $msg.find('.msg__img-link').css('display', 'none');
      };
      $msg.appendTo('.transactions__msgs-cont');
      if (i == messages.length -1){
        scrollToBottom('.transactions__msgs-cont');
      };
    };
  };
};

function scrollToBottom(element) {
  $d = $(element);
  setTimeout(function(){
    $d.scrollTop($d.prop("scrollHeight"));
  },100);
};

consumer.subscriptions.create("ChatMessageChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },
  disconnected() {
    // Called when the subscription has been terminated by the server
  },
  received(data) {
    // Called when there's incoming data on the websocket for this channel
    getMessages(data)
  }
});
