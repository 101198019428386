// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "stylesheets/application"
import "css/helpers"
import "css/slide_home_one"
import "css/slide_home_two"
import "css/index"
import "bootstrap"
import "js/index"
import "js/friendly_transaction"
import "js/payment_subscription"
import "js/reviews"
import "css/general"
import "css/landing"

if (!("Notification" in window)) {
  console.error("This browser does not support desktop notification");
  alert('This browser does not support desktop notification')
} else if (Notification.permission === "granted") {
  //console.log("Permission to receive notifications has been granted");
} else if (Notification.permission !== 'denied') {
  Notification.requestPermission().then(function (params) {
    if (params === "granted") {
      //console.log("Permission to receive notifications has been granted");
      alert('Permission to receive notifications has been granted');
    }
  });
}
