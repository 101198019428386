$(document).on("turbolinks:load", () => {

  $('.membership_express_all_digital').click( function(event) {
    allDigitalPayment(this, true, true);
    event.preventDefault();
  });

  $('.membership_30_all_digital').click( function(event) {
    allDigitalPayment(this);
    event.preventDefault();
  });
  $('.membership_90_all_digital').click( function(event) {
    allDigitalPayment(this);
    event.preventDefault();
  });
  $('.membership_one_pass_all_digital').click( function(event) {
    allDigitalPayment(this, false);
    event.preventDefault();
  });

  $('.ft-payment-all-digital-submit').click( function(event) {
    
    $(this).attr("disabled", "disabled").html("Sending...");

    $.ajax({
      url: '/all_digital_payment',
      method: 'post',
      dataType: 'json',
      data: $(this).parents('#payment-all-digital').serializeArray(),
    }).done(
      function(response){
        console.log(response.status);
        if(response.status === true){
          $(".card.payment-info").hide()
          $(".card.payment-link").show()
          $(".progress-bar-all-digital").html("10");
          $(".payment-link-all-digital").attr("href", response.link);
          // alert(response.link)
          var timeleft = 9;
          var downloadTimer = setInterval(function(){
            if(timeleft <= 0){
              clearInterval(downloadTimer);
              location.href = response.link;
            }
            $(".payment-seconds").html(timeleft);
            $(".progress-bar-all-digital").val(10 - timeleft);
            timeleft -= 1;
          }, 1000);

          
        } else {
          alert("Error")
        }
      }
    ).fail(
      function(response){
        console.log("error")
      }
    );

    event.preventDefault();
  });


  $('.membership_30_kushki').click( function(event) {
    kushkiPayment(this);
    event.preventDefault();
  });
  $('.membership_90_kushki').click( function(event) {
    kushkiPayment(this);
    event.preventDefault();
  });
  $('.membership_one_pass_kushki').click( function(event) {
    kushkiPayment(this, false);
    event.preventDefault();
  });
  $('.membership_express_kushki').click( function(event) {
    kushkiPayment(this, false, true);
    event.preventDefault();
  });

  $('.modal_close').click( function(event) {
    $('#payment-kushki iframe').remove();
    $('#kushki-form').removeClass('is-active');
    event.preventDefault();
  });

  $('.payment-all-digital-close').click( function(event) {
    $('.all-digital-form').removeClass('is-active');
    event.preventDefault();
  });

  
  
  function kushkiPayment(event, is_subscription = true, is_express = false) {
    let uri = '/subscription-validate';
    if(is_express){
      uri = '/express-validate';
    };
    let amount = $(event).attr('data-amount');
    $('#payment-kushki input[name="membership"]').val($(event).attr('data-membership'));
    $.ajax({
      url: uri,
      method: 'post',
      dataType: 'json',
    }).done(
      function(response){
        if(response.status === true){
          kushki(amount, is_subscription);
          $('#kushki-form').addClass('is-active');
        } else {
          alert('You already have subscriptions')
        }
      }
    ).fail(
      function(response){
        console.log("error")
      }
    );
  };


  function allDigitalPayment(event, is_subscription = true, is_express = false) {
    let uri = '/subscription-validate';
    if(is_express){
      uri = '/express-validate';
    };
    let amount = $(event).attr('data-amount');
    let membership = $(event).attr('data-membership');
    $('#payment-all-digital input[name="membership"]').val(membership);
    $.ajax({
      url: uri,
      method: 'post',
      dataType: 'json',
    }).done(
      function(response){
        console.log(response.status);
        console.log(membership);
        if(response.status === true){
          if (membership == 1){
            $('#all-digital-form-one_pass').addClass('is-active');  
          } 
          if (membership == 2){
            $('#all-digital-form-30_days').addClass('is-active');  
          } 
          if (membership == 3){
            $('#all-digital-form-90_days').addClass('is-active');  
          } 
          if (membership == 4){
            $('#all-digital-form-express').addClass('is-active');  
          } 
          
        } else {
          alert('You already have subscriptions')
        }
      }
    ).fail(
      function(response){
        console.log("error")
      }
    );
  };

  function kushki(amount, is_subscription) {
    const kushkiOptions = {
      form: "payment-kushki",
      merchant_id: "6479ad9859944abdb2d8d3d9d0977b5f", 
      amount: amount,
      currency: "USD",
      is_subscription: is_subscription,
      inTestEnvironment: true,
    };
    const kushki = new KushkiCheckout(kushkiOptions);
  };
  if($('.membership_30_paypal_cont').length != 0) {
    plan = $('.membership_30_paypal_cont').attr('data-plan');
    membershipId = $('.membership_30_paypal_cont').attr('data-membership');
    paypalButtonsSubscription(plan, membershipId, ".membership_30_paypal_cont", ".membership_30_paypal")
  };
  if($('.membership_90_paypal_cont').length != 0) {
    plan = $('.membership_90_paypal_cont').attr('data-plan');
    membershipId = $('.membership_90_paypal_cont').attr('data-membership');
    paypalButtonsSubscription(plan, membershipId, ".membership_90_paypal_cont", ".membership_90_paypal")
  };
  if($('.membership_one_pass_paypal_cont').length != 0) {
    membershipId = $('.membership_one_pass_paypal_cont').attr('data-membership');
    amount = $('.membership_one_pass_paypal_cont').attr('data-amount');
    getCreateBtnPayPal(amount, membershipId, ".membership_one_pass_paypal_cont", ".membership_one_pass_paypal", 55, null, null)
  };
  if($('.membership_express_paypal_cont').length !=0) {
    membershipId = $('.membership_express_paypal_cont').attr('data-membership');
    amount = $('.membership_express_paypal_cont').attr('data-amount');
    proposal_id = $('.membership_express_paypal_cont').attr('proposal');
    interested_id = $('.membership_express_paypal_cont').attr('interested');
    getCreateBtnPayPal(amount, membershipId, ".membership_express_paypal_cont", ".membership_express_paypal", 40, proposal_id, interested_id, true)
  };
  function paypalButtonsSubscription(plan, membershipId, getNameCont, getNameBtn) {
    $.ajax({
      url: '/subscription-validate',
      method: 'post',
      dataType: 'json',
    }).done(
      function(response){
        if(response.status === true){
          paypalButtonsSubscriptionRender(plan, membershipId, getNameCont)
        } else {
          $(getNameCont).addClass('is-hidden');
          $(getNameBtn).click(() => { alert('You already have subscriptions') });
        }
      }
    ).fail(
      function(response){
        console.log("error")
      }
    );
  };
  function paypalButtonsSubscriptionRender(plan, membershipId, getNameCont) {
    paypal.Buttons({
      style: {
        layout: 'horizontal',
        color: 'blue',
        shape: 'rect',
        label: 'paypal',
        height: 55,
        tagline: false
      },
      createSubscription: function(data, actions) {
        return actions.subscription.create({
          'plan_id': plan
        });
      },
      onApprove: function(data, actions) {
        $.post('/payment_subscriptions', {
          membership: membershipId,
          method: 'paypal',
          token: data.subscriptionID
        });
      },
      onError: function (err) {
        window.location.reload();
      },
      onCancel: function (data) {
        window.location.reload();
      }
    }).render(getNameCont);
  };
  function getCreateBtnPayPal(amount, membershipId, getNameCont, getNameBtn, heightBtn, proposal_id, interested_id, is_express = false) {
    let uri = '/subscription-validate';
    if(is_express){
      uri = '/express-validate';
    };
    $.ajax({
      url: uri,
      method: 'post',
      dataType: 'json',
    }).done(
      function(response){
        if(response.status === true){
          createBtnPayPal(amount, membershipId, getNameCont, heightBtn, proposal_id, interested_id)
        } else {
          $(getNameCont).addClass('is-hidden');
          $(getNameBtn).click(() => { alert('You already have subscriptions') });
        }
      }
    ).fail(
      function(response){
        console.log("error")
      }
    );
  };
  function createBtnPayPal(amount, membershipId, getNameCont, heightBtn, proposal_id, interested_id) {
    paypal.Buttons({
      style: {
        layout: 'horizontal',
        color: 'blue',
        shape: 'rect',
        label: 'paypal',
        height: heightBtn,
        tagline: false
      },
      createOrder: function(data, actions) {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: amount
            }
          }]
        });
      },
      onApprove: function(data, actions) {
        return actions.order.capture().then(function(details) {
          if(details.status == "COMPLETED") {
            let purchase_units = details.purchase_units[0]
            let capture = purchase_units.payments.captures[0]
            $.post('/payment_subscriptions', {
              membership: membershipId,
              proposal: proposal_id,
              interested: interested_id,
              method: 'paypal',
              token: capture.id,
              status: details.status
            });
          }
        });
      },
      onError: function (err) {
        window.location.reload();
      },
      onCancel: function (data) {
        window.location.reload();
      },
    }).render(getNameCont);
  };
});
