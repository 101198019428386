$(document).on("turbolinks:load", () => {
  const buttonModalProposals = document.getElementById('button_modal_proposals');
  const modalProposals = document.getElementById('modal_proposals');
  const buttonModalHow = document.getElementById('button_modal_how');
  const modalHow = document.getElementById('modal_how');
  const buttonModalCloseTrade = document.getElementById('button_modal_close_trade');

  $(".ft_notification_proposal").on("click", function() {
    id_notification = $('.ft_notification_proposal').attr('notification');
    if (id_notification.length > 0) {
      $.ajax({
        url: '/notifications/read',
        method: 'post',
        dataType: 'json',
        data:{
					notification_id: id_notification
				}
      }).done(
        function(response){
          console.log('Response', response);
        }
      ).fail(
        function(response){
          console.log("error", response);
        }
      );
    }
  });

  $(".ft_notification_transaction").on("click", function() {
    id_notification = $('.ft_notification_transaction').attr('notification');
    if (id_notification.length > 0) {
      $.ajax({
        url: '/notifications/read',
        method: 'post',
        dataType: 'json',
        data:{
					notification_id: id_notification
				}
      }).done(
        function(response){
          console.log('Response', response);
        }
      ).fail(
        function(response){
          console.log("error", response);
        }
      );
    }
  });

  $('.navbar-burger').click(function() {
    $('.navbar-burger').toggleClass('is-active');
    $('.navbar-menu').toggleClass('is-active');
  });
  $('.ft_input_email').on('keyup', function() {
    expr = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    email = $('.ft_input_email').val()
    if (!expr.test(email)){
      $('.ft_input_email').addClass('is-danger')
    } else {
      $('.ft_input_email').removeClass('is-danger')
    }
  });
  $('.ft_input_phone').on('keyup', function() {
    setTimeout(function() {
      if($('.ft_input_phone').val().length < 6 || $('.ft_input_phone').val().length > 12) {
        $('.ft_input_phone').addClass('is-danger')
      } else {
        $('.ft_input_phone').removeClass('is-danger')
      }
    })
  });
  $('.ft_input_university').on('keyup', function() {
    setTimeout(function() {
      if($('.ft_input_university').val().length < 3) {
        $('.ft_input_university').addClass('is-danger')
      } else {
        $('.ft_input_university').removeClass('is-danger')
      }
    })
  });
  $('.ft_input_city').on('keyup', function() {
    setTimeout(function() {
      if($('.ft_input_city').val().length < 3) {
        $('.ft_input_city').addClass('is-danger')
      } else {
        $('.ft_input_city').removeClass('is-danger')
      }
    })
  });
  $('.ft_input_address').on('keyup', function() {
    setTimeout(function() {
      if($('.ft_input_address').val().length < 10) {
        $('.ft_input_address').addClass('is-danger')
      } else {
        $('.ft_input_address').removeClass('is-danger')
      }
    })
  });
  $('.ft_input_company').on('keyup', function() {
    setTimeout(function() {
      if($('.ft_input_company').val().length < 3) {
        $('.ft_input_company').addClass('is-danger')
      } else {
        $('.ft_input_company').removeClass('is-danger')
      }
    })
  });
  $('.ft_input_company_position').on('keyup', function() {
    setTimeout(function() {
      if($('.ft_input_company_position').val().length < 3) {
        $('.ft_input_company_position').addClass('is-danger')
      } else {
        $('.ft_input_company_position').removeClass('is-danger')
      }
    })
  });
  $('.ft_input_company_phone').on('keyup', function() {
    setTimeout(function() {
      if($('.ft_input_company_phone').val().length < 6 || $('.ft_input_company_phone').val().length > 12) {
        $('.ft_input_company_phone').addClass('is-danger')
      } else {
        $('.ft_input_company_phone').removeClass('is-danger')
      }
    })
  });
  $('.ft_input_description').on('keyup', function() {
    setTimeout(function() {
      if($('.ft_input_description').val().length < 4) {
        $('.ft_input_description').addClass('is-danger')
      } else {
        $('.ft_input_description').removeClass('is-danger')
      }
    })
  });
  $('#modal_payment_subscription').click(function() {
    $('.modal').removeClass('is-active');
  });
  if (buttonModalProposals) {
    buttonModalProposals.addEventListener('click', function () {
      modalProposals.classList.add('is-active')
    });
    modalProposals.addEventListener('click', e => {
      const elementClose = e.target
      if (elementClose.classList.contains('modal-close') || elementClose.classList.contains('modal-background')) {
        modalProposals.classList.remove('is-active')
      }
    })
  };
  if (buttonModalHow) {
    buttonModalHow.addEventListener('click', function () {
      modalHow.classList.add('is-active')
    });
    modalHow.addEventListener('click', e => {
      const elementClose = e.target
      if (elementClose.classList.contains('modal-close') || elementClose.classList.contains('modal-background')) {
        modalHow.classList.remove('is-active')
      }
    })
  };
  if (buttonModalCloseTrade) {
    const modalCloseTrade = document.getElementById('modal_close_trade');
    $('.button_modal_class_close_trade').click(function() {
      modalCloseTrade.classList.add('is-active')
    });
    modalCloseTrade.addEventListener('click', e => {
      const elementClose = e.target
      if (elementClose.classList.contains('modal-background')) {
        modalCloseTrade.classList.remove('is-active')
      }
    })
  };
});
